
/*****************************************
* ECCO - MIXINS
*****************************************/

@mixin grid( $breakpointMap... ) {
	//@debug "=====================================";
	//@debug "Zacinam pocitat grid";
	$previousMargin: 0;
	$nextMapIndex: 1;

	$firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);
	//@debug "Prvni breakpoint z mapy je #{$firstBreakpoint}";

	/****************
	* ZÁKLADNÍ STYLY
	****************/

	float: left;

	/****************
	* /ZÁKLADNÍ STYLY
	****************/

	@include bp("< #{$firstBreakpoint}") {
		width: 100%;
	}

	@each $breakpoint in $breakpointMap { 
		$nextMapIndex: $nextMapIndex + 1;
		$nextMapValue: if( length($breakpointMap) >= $nextMapIndex, nth(nth($breakpointMap, $nextMapIndex), 1), false );

		$currentBreakpoint: nth($breakpoint, 1);
		$amount: nth($breakpoint, 2);
		$margin: if( length($breakpoint) > 2, nth($breakpoint, 3), $previousMargin );
		$previousMargin: $margin;
		//$marginUnit: unit($margin);
		//$isPercentage: false;

		// @if unitless($margin) OR $marginUnit == unquote("%") {
		// 	$isPercentage: true;
		// }

		@if $nextMapValue != false {
			//@debug "Aktualni breakpoint je #{$currentBreakpoint} a dalsi breakpoint je #{$nextMapValue}, pouzijeme >= <";
			@include bp(">= #{$currentBreakpoint}", "< #{$nextMapValue}") {
				@include grid-column( $amount, $margin );
			}
		} @else {
			//@debug "Konecny breakpoint je #{$currentBreakpoint}, pouzijeme >=";
			@include bp(">= #{$currentBreakpoint}") {
				@include grid-column( $amount, $margin );
			}
		}
	}

	//@debug "Konec grid";
	//@debug "=====================================";
}


@mixin grid-column( $amount, $margin ) {
	//@debug ">>> Pocitam sirku pro jeden element v gridu";
	$width: null;

	@if unitless($margin) {
		//@debug "Margin #{$margin} nema jednotku, prevadim na procenta";
		$margin: percentage(strip-unit($margin) / 100);
	}

	@if unit($margin) == unquote("%") {
		//@debug "Margin je v procentech";
		$marginTotal: percentage(strip-unit($margin) / 100);
		$width: (100% - ($marginTotal * ($amount - 1))) / $amount;
	} @else {
		//@debug "Margin je v pixelech";
		$widthItem: 100% / $amount;
		$marginTotal: ( $margin * ( $amount - 1 ) ) / $amount;
		$width: if( $amount > 1, calc( #{$widthItem} - #{$marginTotal} ), 100%);
	}

	// @debug unquote(str-pad("width", 7)) unquote(":") #{$width};
	// @debug unquote(str-pad("margin", 7)) unquote(":") #{$margin};
	width: $width;
	margin-left: $margin;

	&:nth-of-type(#{$amount}n+1) {
		margin-left: 0;
		clear: left;
	}

	//@debug "Konec vypoctu pro element v gridu <<<";
}


@mixin simplified-grid( $breakpointMap... ) {
	$nextMapIndex: 1;
	$firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);

	@include bp("< #{$firstBreakpoint}") {
		width: 100%;
	}

	@each $breakpoint in $breakpointMap { 
		$nextMapIndex: $nextMapIndex + 1;
		$nextMapValue: if( length($breakpointMap) >= $nextMapIndex, nth(nth($breakpointMap, $nextMapIndex), 1), false );

		$currentBreakpoint: nth($breakpoint, 1);
		$amount: nth($breakpoint, 2);

		@if $nextMapValue != false {
			@include bp(">= #{$currentBreakpoint}", "< #{$nextMapValue}") {
				width: ( 100% / $amount );

				//&:nth-of-type(#{$amount}n+1) {
				//	clear: left;
				//}
			}
		} @else {
			@include bp(">= #{$currentBreakpoint}") {
				width: ( 100% / $amount );

				//&:nth-of-type(#{$amount}n+1) {
				//	clear: left;
				//}
			}
		}
	}
}


@mixin scrollable-items( $amount, $margin ) {
	$total: length(&);
	$selector-map: ();
	
	@each $s in & {
		$selector: list-pop($s);
		$selector-map: append($selector-map, $selector, comma);
	}

	$compound: implode($selector-map, ", ");

	@at-root {
		#{$compound} {
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
			white-space: nowrap;
			font-size: 0;
		}
	}

	display: inline-block;
	vertical-align: top;
	white-space: normal;
	font-size: 1rem;
	
	$width: null;

	@if unitless($margin) {
		//@debug "Margin #{$margin} nema jednotku, prevadim na procenta";
		$margin: percentage(strip-unit($margin) / 100);
	}

	@if unit($margin) == unquote("%") {
		//@debug "Margin je v procentech";
		$marginTotal: percentage(strip-unit($margin) / 100);
		$width: (100% - ($marginTotal * ($amount - 1))) / $amount;
	} @else {
		//@debug "Margin je v pixelech";
		$widthItem: 100% / $amount;
		$marginTotal: ( $margin * ( $amount - 1 ) ) / $amount;
		$width: if( $amount > 1, calc( #{$widthItem} - #{$marginTotal} ), 100%);
	}

	// @debug unquote(str-pad("width", 7)) unquote(":") #{$width};
	// @debug unquote(str-pad("margin", 7)) unquote(":") #{$margin};
	width: $width;
	margin-left: $margin;
	margin-top: 0;

	&:first-child {
		margin-left: 0;
	}

	@content;
}


@mixin box-sizing( $sizing: border-box ) {
	@if $using-autoprefixer == false {
		-webkit-box-sizing: $sizing;
		-moz-box-sizing: $sizing;
	} @else {
		box-sizing: $sizing;
	}
}


@mixin link( $selector: a ) {
	@if length($selector) > 1 {
		@each $s in $selector {
//			@debug #{$s};
			#{$s},
			#{$s}:link,
			#{$s}:visited {
				@content;
			}
		}
	} @else {
		@if $selector != & {
			$selector: unquote($selector);

			#{$selector},
			#{$selector}:link,
			#{$selector}:visited {
				@content;
			}
		} @else {
			&,
			&:link,
			&:visited {
				@content;
			}
		}
	}
}


@mixin link-over( $selector: a ) {
	@if length($selector) > 1 {
		@each $s in $selector {
			#{$s}:hover,
			#{$s}:focus,
			#{$s}:active {
				@content;
			}			
		}
	} @else {
		@if $selector != & {
			$selector: unquote($selector);

			#{$selector}:hover,
			#{$selector}:focus,
			#{$selector}:active {
				@content;
			}
		} @else {
			&:hover,
			&:focus,
			&:active {
				@content;
			}
		}
	}
}

@mixin link-all( $selector: a ) {
	@if length($selector) > 1 {
		@each $s in $selector {
			#{$s},
			#{$s}:link,
			#{$s}:visited,
			#{$s}:hover,
			#{$s}:focus,
			#{$s}:active {
				@content;
			}			
		}
	} @else {
		@if $selector != & {
			$selector: unquote($selector);

			#{$selector},
			#{$selector}:link,
			#{$selector}:visited,
			#{$selector}:hover,
			#{$selector}:focus,
			#{$selector}:active {
				@content;
			}
		} @else {
			&,
			&:link,
			&:visited,
			&:hover,
			&:focus,
			&:active {
				@content;
			}
		}
	}
}


@mixin selection {
	@if null != & {
		&::-moz-selection {
			@content;
		}

		&::selection {
			@content;	
		}
	} @else {
		::-moz-selection {
			@content;
		}

		::selection {
			@content;
		}
	}
}


@mixin user-select( $value ) {
	@if $using-autoprefixer == false {
		-webkit-user-select: $value;
		-moz-user-select: $value;
		-ms-user-select: $value;
	}

	user-select: $value;
}


@mixin keyframes( $name, $valuePrefixes ) {
	@if $using-autoprefixer == false {
		@-webkit-keyframes #{$name} {
			@content;
		}
	}

	@keyframes #{$name} {
		@content;
	}
}


@mixin animation( $animation ) {
	@if $using-autoprefixer == false {
		-webkit-animation: $animation;
	}

	animation: $animation;
}


@mixin animation-name( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-name: $value;
	}

	animation-name: $value;
}


@mixin animation-fill-mode( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-fill-mode: $value;
	}

	animation-fill-mode: $value;
}


@mixin animation-timing-function( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-timing-function: $value;
	}

	animation-timing-function: $value;
}


@mixin animation-duration( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-duration: $value;
	}

	animation-duration: $value;
}


@mixin animation-iteration-count( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-iteration-count: $value;
	}

	animation-iteration-count: $value;
}


@mixin animation-delay( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-delay: $value;
	}

	animation-delay: $value;
}


@mixin animation-play-state( $value ) {
	@if $using-autoprefixer == false {
		-webkit-animation-play-state: $value;
	}

	animation-play-state: $value;
}


@mixin transition( $value... ) {
	@if $using-autoprefixer == false {
		-webkit-transition: $value;
	}

	transition: $value;
}


@mixin transition-property( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transition-property: $value;
	}

	transition-property: $value;
}


@mixin transition-delay( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transition-delay: $value;
	}

	transition-delay: $value;
}


@mixin transition-duration( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transition-duration: $value;
	}

	transition-duration: $value;
}


@mixin transition-timing-function( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transition-timing-function:  $value;
	}

	transition-timing-function:  $value;
}


@mixin transform( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transform:  $value;
		-ms-transform:  $value;
	}
	
	transform:  $value;
}


@mixin transform-origin( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transform-origin:  $value;
		-ms-transform-origin:  $value;
	}

	transform-origin:  $value;
}


@mixin transform-style( $value ) {
	@if $using-autoprefixer == false {
		-webkit-transform-style: $value;
		-ms-transform-style: $value;
	}

	transform-style: $value;
}


@mixin perspective( $value ) {
	@if $using-autoprefixer == false {
		-webkit-perspective: $value;
	}

	perspective: $value;
}


@mixin perspective-origin( $value ) {
	@if $using-autoprefixer == false {
		-webkit-perspective-origin: $value;
	}

	perspective-origin: $value;
}


@mixin backface-visibility( $value ) {
	@if $using-autoprefixer == false {
		-webkit-backface-visibility: $value;
	}

	backface-visibility: $value;
}


@mixin landscape {
	@media only screen and (orientation: landscape) {
		@content;
	}
}


@mixin portrait {
	@media only screen and (orientation: portrait) {
		@content;
	}
}


@mixin font-size( $size, $important: false ) {
	$baseRem: 1rem !default;
	$basePixels: 16px !default;
	$imp: if( $important == false, "", "!important" );

	@if unit($size) == px {
		$basePixels: $size;
		$baseRem: rem($size);
	} @else {
		@warn "Velikost musi byt zadana v pixelech";
	}

	font-size: unquote("#{$basePixels}#{$imp}");
	font-size: unquote("#{$baseRem}#{$imp}");
}


@mixin font-feature-settings( $value... ) {
	@if $using-autoprefixer == false {
		-webkit-font-feature-settings: $value;
		-moz-font-feature-settings: $value;
	}

	font-feature-settings: $value;
}


@mixin font-face( $fontFaceMap, $fileName, $fontPath: $default-font-path) {
	$family: map-get($fontFaceMap, family);
	$weight: if( map-has-key($fontFaceMap, weight), map-get($fontFaceMap, weight), normal );
	$style: if( map-has-key($fontFaceMap, style), map-get($fontFaceMap, style), normal );

	@font-face {
		font: {
			family: "#{$family}";
			weight: $weight;
			style: $style;
		}

		src: url("#{$fontPath}#{$fileName}.woff2") format("woff2"),
		     url("#{$fontPath}#{$fileName}.woff") format("woff"),
		     url("#{$fontPath}#{$fileName}.ttf") format("truetype");
		font-display: swap;
	}
}


@mixin font($family, $allstyles: true) {
	$family: unquote($family);

	@if $family == OswaldWeb {
		@include font-face(( family : $family ), "oswald-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "oswald-bold-webfont");
			@include font-face(( family : $family, weight : 200 ), "oswald-light-webfont");
		}
	} @else if $family == ArmataRegularWeb {
		@include font-face(( family : $family ), "armata-regular-web");
	} @else if $family == GnuolaneFreeWeb {
		/*! * Web Fonts from fontspring.com * * All OpenType features and all extended glyphs have been removed. * Fully installable fonts can be purchased at http://www.fontspring.com * * The fonts included in this stylesheet are subject to the End User License you purchased * from Fontspring. The fonts are protected under domestic and international trademark and * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or * distributing this font software. * * (c) 2010-2012 Fontspring * * * * * The fonts included are copyrighted by the vendor listed below. * * Vendor: Typodermic * License URL: http://www.fontspring.com/fflicense/typodermic * * */ 
		@include font-face(( family : $family ), "gnuolane_free-web");
	} @else if $family == OpenSansWeb {
		@include font-face(( family : $family ), "opensans-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "opensans-bold-web");
			@include font-face(( family : $family, weight : 300 ), "opensans-light-web");
		}
	} @else if $family == AntonWeb {
		@include font-face(( family : $family ), "anton-web", "antonregular");
	} @else if $family == ArchivoNarrowWeb {
		@include font-face(( family : $family ), "archivonarrow-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "archivonarrow-bold-web");
		}
	} @else if $family == SignikaNegativeWeb {
		@include font-face(( family : $family ), "signikanegative-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "signikanegative-bold-web");
			@include font-face(( family : $family, weight : 200 ), "signikanegative-light-web");
		}
	} @else if $family == FiraSansWeb {
		@include font-face(( family : $family ), "firasans-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "firasans-bold-web");
			@include font-face(( family : $family, weight : 200 ), "firasans-light-web");
		}
	} @else if $family == MerriweatherSansWeb {
		@include font-face(( family : $family ), "merriweathersans-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "merriweathersans-bold-web");
		}
	} @else if $family == PlayWeb {
		@include font-face(( family : $family ), "play-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "play-bold-web");
		}
	} @else if $family == AdventProWeb {
		@include font-face(( family : $family ), "adventpro-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "adventpro-bold-web");
		}
	} @else if $family == TinosWeb {
		@include font-face(( family : $family ), "tinos-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "tinos-bold-web");
		}
	} @else if $family == RobotoCondensedWeb {
		@include font-face(( family : $family ), "robotocondensed-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "robotocondensed-light-web");
			@include font-face(( family : $family, weight : bold ), "robotocondensed-bold-web");
		}
	} @else if $family == GreatVibesWeb {
		@include font-face(( family : $family ), "greatvibes-regular-web");
	} @else if $family == RobotoWeb {
		@include font-face(( family : $family ), "roboto-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 100 ), "roboto-thin-webfont");
			@include font-face(( family : $family, weight : 300 ), "roboto-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "roboto-medium-webfont");
			@include font-face(( family : $family, weight : bold ), "roboto-bold-webfont");
		}
	} @else if $family == RobotoSlabWeb {
		@include font-face(( family : $family ), "robotoslab");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "robotoslablight");
			@include font-face(( family : $family, weight : bold ), "robotoslabbold");
		}
	} @else if $family == TekoWeb {
		@include font-face(( family : $family ), "teko-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "teko-light-web");
			@include font-face(( family : $family, weight : 500 ), "teko-medium-web");
			@include font-face(( family : $family, weight : bold ), "teko-semibold-web");
		}
	} @else if $family == Adelle {
		@include font-face(( family : $family ), "adelle_reg-web");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "adelle_light-web");
			@include font-face(( family : $family, weight : 600 ), "adelle_semibold-web");
			@include font-face(( family : $family, weight : bold ), "adelle_bold-web");
		}
	} @else if $family == AdelleWeb {
		@include font(Adelle);
	} @else if $family == LibreBaskervilleWeb {
		@include font-face(( family : $family ), "librebaskerville-regular-web");

		@if $allstyles {
			@include font-face(( family : $family, style : italic ), "librebaskerville-italic-web");
			@include font-face(( family : $family, weight : bold ), "librebaskerville-bold-web");
		}
	} @else if $family == MontserratWeb {
		@include font-face(( family : $family ), "montserrat-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 100 ), "montserrat-ultralight-webfont");
			@include font-face(( family : $family, weight : 300 ), "montserrat-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "montserrat-semibold-webfont");
			@include font-face(( family : $family, weight : 600), "montserrat-bold-webfont");
		}
	} @else if $family == UbuntuCondensedWeb {
		@include font-face(( family : $family ), "ubuntu-condensed-web");
	} @else if $family == StintUltraCondensedWeb {
		@include font-face(( family : $family ), "stintultracondensed-regular-webfont");
	} @else if $family == OpenSansCondensedWeb {
		@include font-face(( family : $family ), "opensans-condlight-web");
	} @else if $family == MagraWeb {
		@include font-face(( family : $family ), "magra-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "magra-bold-webfont");
		}
	} @else if $family == OxygenWeb {
		@include font-face(( family : $family ), "oxygen-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "oxygen-light-webfont");
			@include font-face(( family : $family, weight : bold ), "oxygen-bold-webfont");
		}
	} @else if $family == SourceCodeProWeb {
		@include font-face(( family : $family ), "sourcecodepro-regular");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "sourcecodepro-bold");
		}
	} @else if $family == NotoSansWeb {
		@include font-face(( family : $family ), "notosans-regular-webfont");

		@if $allstyles == true {
			@include font-face(( family : $family, weight : bold ), "notosans-bold-webfont");
		}
	} @else if $family == RalewayWeb {
		@include font-face(( family : $family ), "raleway-regular-web");

		@if $allstyles == true {
			@include font-face(( family : $family, weight : bold ), "raleway-bold-web");
		}
	} @else if $family == UbuntuWeb {
		@include font-face(( family : $family ), "ubuntu-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "ubuntu-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "ubuntu-medium-webfont");
			@include font-face(( family : $family, weight : bold ), "ubuntu-bold-webfont");
		}
	} @else if $family == Exo2Web {
		@include font-face(( family : $family ), "exo2-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 600 ), "exo2-semibold-webfont");
			@include font-face(( family : $family, weight : bold ), "exo2-bold-webfont");
		}
	} @else if $family == RajdhaniWeb {
		@include font-face(( family : $family ), "rajdhani-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 500 ), "rajdhani-medium-webfont");
			@include font-face(( family : $family, weight : 600 ), "rajdhani-semibold-webfont");
			@include font-face(( family : $family, weight : bold ), "rajdhani-bold-webfont");
		}
	} @else if $family == FiraSansCondensedWeb {
		@include font-face(( family : $family ), "firasanscondensed-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 500 ), "firasanscondensed-medium-webfont");
			@include font-face(( family : $family, weight : bold ), "firasanscondensed-bold-webfont");
		}
	} @else if $family == AmaticSCWeb {
		@include font-face(( family : $family ), "amaticsc-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : bold ), "amaticsc-bold-webfont");
		}
	} @else if $family == MuktaVaaniWeb {
		@include font-face(( family : $family ), "muktavaani-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "muktavaani-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "muktavaani-medium-webfont");
			@include font-face(( family : $family, weight : bold ), "muktavaani-bold-webfont");
		}
	} @else if $family == AverageSansWeb {
		@include font-face(( family : $family ), "averagesans-regular-webfont");
	} @else if $family == TitilliumWeb {
		@include font-face(( family : $family ), "titilliumweb-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "titilliumweb-light-webfont");
			@include font-face(( family : $family, weight : 600 ), "titilliumweb-semibold-webfont");
			@include font-face(( family : $family, weight : bold ), "titilliumweb-bold-webfont");
		}
	} @else if $family == FiraSansExtraCondensedWeb {
		@include font-face(( family : $family ), "firasansextracondensed-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "firasansextracondensed-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "firasansextracondensed-medium-webfont");
			@include font-face(( family : $family, weight : bold ), "firasansextracondensed-bold-webfont");
		}
	} @else if $family == EncodeSansCondensedWeb {
		@include font-face(( family : $family ), "encodesanscondensed-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "encodesanscondensed-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "encodesanscondensed-medium-webfont");
			@include font-face(( family : $family, weight : 600 ), "encodesanscondensed-semibold-webfont");
			@include font-face(( family : $family, weight : bold ), "encodesanscondensed-bold-webfont");
		}
	} @else if $family == HindVadodaraWeb {
		@include font-face(( family : $family ), "hindvadodara-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 300 ), "hindvadodara-light-webfont");
			@include font-face(( family : $family, weight : 500 ), "hindvadodara-medium-webfont");
			@include font-face(( family : $family, weight : 600 ), "hindvadodara-semibold-webfont");
			@include font-face(( family : $family, weight : bold ), "hindvadodara-bold-webfont");
		}
	} @else if $family == LibreFranklinWeb {
		@include font-face(( family : $family ), "librefranklin-regular-webfont");

		@if $allstyles {
			@include font-face(( family : $family, weight : 500 ), "librefranklin-medium-webfont");
			@include font-face(( family : $family, weight : bold ), "librefranklin-bold-webfont");
		}
	} @else {
		@error "Font '#{$family}' v knihovne neexistuje.";
	}
}


@mixin firefox-only {
	@-moz-document url-prefix() {
		@content;
	}
}


@mixin msie-only {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}


@mixin edge-only {
	@supports (-ms-ime-align: auto) {
      @content;
	}
}

@mixin iphonex-only {
	@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
		@content;
	}
}


@mixin bp( $expression, $expression2 : null ) {
	$operators: (
		">" : "min-width",
		">=" : "min-width",
		"<" : "max-width",
		"<=" : "max-width",
		"=<" : "max-width"
	);

	$spaceIndex: str-index($expression, " ");
	$value: null;
	$operator: null;

	@if $spaceIndex == null {
		$operator: ">";
		$value: to-number(unquote($expression));
	} @else {
		$operator: unquote(str-slice($expression, 1, $spaceIndex - 1));
		//$len: str-length($operator);
		$value: to-number(unquote(str-slice($expression, $spaceIndex + 1)));
	}

	$isEm: unit($value) == em;

	@if $operator == ">" {
		$value: if($isEm, $value + .01em, $value + 1px);
	} @else if $operator == "<" {
		$value: if($isEm, $value - .01em, $value - 1px);
	}

	@if map-has-key($operators, $operator) {
		@if $expression2 == null {
			$query: map-get($operators, $operator);
			
			@media screen and ($query: $value) {
				@content;
			}
		} @else {
			$spaceIndex2: str-index($expression2, " ");
			$operator2: unquote(str-slice($expression2, 1, $spaceIndex2 - 1));
			$value2: to-number(unquote(str-slice($expression2, $spaceIndex2 + 1)));
			$isEm: unit($value2) == em;

			@if $operator2 == ">" {
				$value2: if($isEm, $value2 + .01em, $value2 + 1px);
			} @else if $operator2 == "<" {
				$value2: if($isEm, $value2 - .01em, $value2 - 1px);
			}

			@if map-has-key($operators, $operator2) {
				$query: map-get($operators, $operator);
				$query2: map-get($operators, $operator2);
			
				@media screen and ($query: $value) and ($query2: $value2) {
					@content;
				}
			} @else {
				@error "Neznamy operator #{$operator2}";
			}
		}
	} @else {
		@error "Neznamy operator #{$operator}";
	}
}

@mixin limit-items( $limit, $selector: null ) {
	@if $selector == null {
		$last: null;

		@each $s in & {
			//@debug $s;
			$l: length($s);
			$last: nth($s, $l);
		}

		&:nth-of-type(#{$limit}) ~ #{$last} {
			display: none;
		}
	} @else {
		#{$selector}:nth-of-type(#{$limit}) ~ li {
			display: none;
		}
	}
}

@mixin quantity-query( $condition, $selector: & ) {
	$conditionComplexity: length($condition);

	$least: nth( $condition, 2 );
	$selectorMap: ();

	@each $s in & {
		//@debug $s;
		$l: length($s);
		$last: nth($s, $l);

		$s: implode($s, " ");
		//@debug type-of($s);
		//@debug #{$s};

		@if $conditionComplexity == 2 {
			//@debug "complexity 2";
			$cond: nth( $condition, 1 );
			//@debug #{$cond};
			@if $cond == min {
			//@debug "condition min";
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least})", space);
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}) ~ #{$last}", space);
			} @else if $cond == max {
			//@debug "condition max";
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(-n+#{$least})", space);
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(-n+#{$least}) ~ #{$last}", space);
			} @else if $cond == exactly {
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child", space);
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child ~ #{$last}", space);	
			}
		} @else {
			$most: nth( $condition, 4 );
			//@debug "complexity 4";
			$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child", space);
			$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child ~ #{$last}", space);
		}
	}

	$compound: implode($selectorMap, ", ");

	@at-root {
		#{$compound} {
			@content;
		}
	}
}


@mixin column-count( $value ) {
	@if $using-autoprefixer == false {
		-webkit-column-count: $value;
		-moz-column-count: $value;
	}

	column-count: $value;
}


@mixin column-gap( $value ) {
	@if $using-autoprefixer == false {
		-webkit-column-gap: $value;
		-moz-column-gap: $value;
	}

	column-gap: $value;
}


@mixin column-span( $value ) {
	@if $using-autoprefixer == false {
		-webkit-column-span: $value;
		-moz-column-span: $value;
	}

	column-span: $value;
}


@mixin column-width( $value ) {
	@if $using-autoprefixer == false {
		-webkit-column-width: $value;
		-moz-column-width: $value;
	}

	column-width: $value;
}


@mixin break-inside( $value ) {
	-webkit-column-break-inside: $value;
	page-break-inside: $value;
	break-inside: $value;
}


@mixin radius( $position, $value ) {
	$position: unquote($position);

	@if $position == top {
		border-radius: $value $value 0 0;
	} @else if $position == right {
		border-radius: 0 $value $value 0;
	} @else if $position == bottom {
		border-radius: 0 0 $value $value;
	} @else if $position == left {
		border-radius: $value 0 0 $value;
	} @else {
		@debug "Mixin radius - parametr $position musi byt top/right/bottom/left";
	}
}

@mixin fill( $offsets : 0 0 0 0, $position: absolute ) {
	//$offsets: explode(quote($offsets), " ");
	$top: nth($offsets, 1);
	$right: nth($offsets, 2);
	$bottom: nth($offsets, 3);
	$left: nth($offsets, 4);

	$top: if($top, $top, 0);
	$right: if($right, $right, 0);
	$bottom: if($bottom, $bottom, 0);
	$left: if($left, $left, 0);

	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}


@mixin css-grid( $breakpointMap... ) {
	//@debug "=====================================";
	//@debug "Zacinam pocitat grid";
	$previousMarginLeft: 0;
	$previousMarginBottom: 0;
	$nextMapIndex: 1;

	$firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);
	//@debug "Prvni breakpoint z mapy je #{$firstBreakpoint}";

	/****************
	* /ZÁKLADNÍ STYLY
	****************/

	@include bp("> #{$firstBreakpoint}") {
		display: grid;
		grid-auto-flow: row;
	}

	@each $breakpoint in $breakpointMap { 
		// @debug "previousLeft" #{$previousMarginLeft};
		// @debug "previousBottom" #{$previousMarginBottom};	
		$nextMapIndex: $nextMapIndex + 1;
		$nextMapValue: if( length($breakpointMap) >= $nextMapIndex, nth(nth($breakpointMap, $nextMapIndex), 1), false );

		$currentBreakpoint: nth($breakpoint, 1);
		$amount: nth($breakpoint, 2);
		$marginLeft: 0;
		$marginBottom: 0;

		@if length($breakpoint) > 2 {
			@if length($breakpoint) > 3 {
				$marginLeft: nth($breakpoint, 3);
				$marginBottom: nth($breakpoint, 4);
			} @else {
				$marginLeft: nth($breakpoint, 3);
				$marginBottom: nth($breakpoint, 3);
			}
		} @else {
			$marginLeft: $previousMarginLeft;
			$marginBottom: $previousMarginBottom;
		}
		// @debug "left" #{$marginLeft};
		// @debug "bottom" #{$marginBottom};
		$previousMarginLeft: $marginLeft;
		$previousMarginBottom: $marginBottom;

		@if $nextMapValue != false {
			@include bp(">= #{$currentBreakpoint}", "< #{$nextMapValue}") {
				grid-template-columns: repeat($amount, 1fr);
				grid-gap: $marginLeft $marginBottom;
			}
		} @else {
			@include bp(">= #{$currentBreakpoint}") {
				grid-template-columns: repeat($amount, 1fr);
				grid-gap: $marginLeft $marginBottom;
			}
		}
	}

	//@debug "Konec grid";
	//@debug "=====================================";
}

@mixin media( $breakpoint, $rules, $selectors : & ) {
  $current-media-list: ();
  @if map-has-key($ecco-queries, $breakpoint) {
    $current-media-list: map-get($ecco-queries, $breakpoint);
  }
  //@debug $current-media-list;
  $ecco-queries: map-merge($ecco-queries,
            ($breakpoint: append($current-media-list, ($selectors, $rules)))) !global;
  //@debug $ecco-queries;
}

@mixin dump-media {
  @each $breakpoint, $data in $ecco-queries {
    @include bp($breakpoint) {
      @each $selector, $rules in $data {
        #{$selector} {
          @each $name, $value in $rules {
            #{$name}: $value;
          }
        }
      }
    }
  }

  $ecco-queries: () !global;
}

@mixin gc-class( $min-width, $width ) {
	$classNumber: strip-unit($min-width);
	$widthPercentage: ( 100% / $width );

	@include bp("> #{$min-width}") {
		.gc-#{$classNumber}--#{$width} {
			width: $widthPercentage;
		}
	}
}

@mixin pad( $color, $side : left, $element : before, $width : 999em ) {
	$from : if( $side == right, left, right );

	&::#{$element} {
		content: "";
		position: absolute;
		width: $width;
		#{$from}: 100%;
		top: 0;
		bottom: 0;
		background-color: $color;
	}
}


@mixin dynamic-grid( $max-items, $gap, $item-min-width: 18em /* cca 290px při běžné velikosti písma */ ) {
	$gap-type: type-of($gap);
	$basis: 100% / $max-items;

	display: flex;
	flex-wrap: wrap;

	@if $gap-type == number {
		margin-left: -$gap;
		margin-right: 0;

		> * {
			//flex-basis: calc(#{$basis} - #{$gap});
			flex-basis: $basis;
			display: flex;
			flex-grow: 1;
			min-width: $item-min-width;
			margin-bottom: $gap;
			padding-left: $gap;

			@include msie-only() {
				flex-basis: calc(#{$basis} - #{$gap});
			}
		}
	} @else if $gap-type == list {
		> * {
			display: flex;
			flex-grow: 1;
			min-width: $item-min-width;
		}

		$next-index: 1;

		@each $gap-value in $gap {
			$next-index: $next-index + 1;
			$length: length($gap-value);

			@if $length == 1 {
				margin-left: -$gap-value;

				> * {
					//flex-basis: calc(#{$basis} - #{$gap-value});
					flex-basis: $basis;
					margin-bottom: $gap-value;
					padding-left: $gap-value;

					@include msie-only() {
						flex-basis: calc(#{$basis} - #{$gap-value});
					}
				}
			} @else {
				$next-value: if( length($gap) >= $next-index, nth(nth($gap, $next-index), 1), false );
				$current-breakpoint: nth($gap-value, 1);
				$margin: nth($gap-value, 2);

				@if $next-value != false {
					@include bp(">= #{$current-breakpoint}", "< #{$next-value}") {
						margin-left: -$margin;

						> * {
							//flex-basis: calc(#{$basis} - #{$margin});
							flex-basis: $basis;
							margin-bottom: $margin;
							padding-left: $margin;

							@include msie-only() {
								flex-basis: calc(#{$basis} - #{$margin});
							}
						}
					}
				} @else {
					@include bp(">= #{$current-breakpoint}") {
						margin-left: -$margin;

						> * {
							//flex-basis: calc(#{$basis} - #{$margin});
							flex-basis: $basis;
							margin-bottom: $margin;
							padding-left: $margin;
							
							@include msie-only() {
								flex-basis: calc(#{$basis} - #{$margin});
							}
						}
					}
				}
			}
		}
	}
}


